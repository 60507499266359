export const useResourceStore = defineStore('resourceStore', {
  state: () => ({
    carriers: [],
    carriersLoading: false,
    vessels: [],
    vesselsLoading: false,
    ports: [],
    portsLoading: false,
    data: [],
  }),
  actions: {
    async fetchCarriers(query?: string) {
      try {
        this.carriersLoading = true;
        const { results }: any = await $fetch(`/api/resource/lookup?q=${query || '*'}&type=carrier&per_page=20`, { method: 'GET' });
        this.carriers = results?.map(({ scac, name, scac_synonym }: any) => ({ label: `${scac}-${name}${scac_synonym?.length ? `(${scac_synonym.join(',')})` : ''}`, id: scac })).sort((a: any, b: any) => a.label.localeCompare(b.label));
        return this.carriers;
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.carriersLoading = false;
      }
    },
    async fetchVessels(query: string) {
      try {
        this.vesselsLoading = true;
        const { results }: any = await $fetch(`/api/resource/lookup?q=${query || '*'}&type=vessel`, { method: 'GET' });
        this.vessels = results?.map(({ imo, name }: any) => ({ label: `${name}-${imo}`, id: imo }));
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.vesselsLoading = false;
      }
    },
    async fetchPorts(query: string) {
      try {
        this.portsLoading = true;
        const { results }: any = await $fetch(`/api/resource/ports?q=${query || '*'}`, { method: 'GET' });
        return sortAListByKey(results, 'name');
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.portsLoading = false;
      }
    },
    async fetchCountries() {
      const { $ofetch }: any = useNuxtApp();
      return await $ofetch(`/api/resource/port-countries`).then((res: any) => {
        return sortAListByKey(res, 'country');
      }).catch((err: any) => console.log(err));
    },
    async fetchResourceLookup() {
      const route = useRoute();
      const queryParams: any = {
        q: route.query.q || '*',
        type: route.params.type,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      const { results }: any = await $fetch(`/api/resource/lookup?${queryString}`, { method: 'POST' });
      const uniqueLatestResults = results.reduce((acc: any, current: any) => {
        const existing = acc.find((item: any) => item.name === current.name);
        if (existing) {
          if (current.updated > existing.updated) {
            return acc.map((item: any) => item.name === current.name ? current : item);
          }
          else {
            return acc;
          }
        }
        return [...acc, current];
      }, []);
      this.data = uniqueLatestResults;
    },
    async getPortInfoByCode(code: any) {
      return await $fetch(`/api/resource/port-by-code?code=${code}`)
        .then((res: any) => {
          return res[0];
        }).catch((err: any) => console.log(err));
    },
  },
});
